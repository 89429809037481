@import 'styles/config';

.loggedinUser {
  display: flex;
  align-items: center;
  align-content: center;
  gap: var(--gutter);

  &__user {
    font-weight: 500;

    color: $color-secondary-400;

    @media (max-width: $max-tablet) {
      display: none;
    }
  }

  &__notifications {
    position: relative;

    margin-left: 37px;

    width: 20px;
    height: 20px;

    color: var(--color-primary-ui, $color-primary);
  }

  &__notificationsCount {
    position: absolute;
    top: -6px;
    right: -10px;

    width: 18px;
    height: 18px;

    border-radius: 50%;

    font-family: 'Roboto', 'Droid Sans', 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;

    background: $color-red;
    color: #fff;
  }
}
