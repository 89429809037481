@import 'styles/config';

.nav {
  &__item {
    margin-bottom: 10px;
  }

  &__link {
    display: flex;
    align-content: center;
    align-items: center;

    padding: 10px 10px 10px 24px;

    text-decoration: none;

    color: $color-secondary-400;

    &:hover,
    &:focus,
    &:active {
      border-radius: 8px;

      text-decoration: none;

      background: $color-secondary-50;
    }

    > svg {
      margin-right: 16px;

      width: 20px;
      height: 20px;
    }

    &[aria-current] {
      border-radius: 8px;

      font-weight: 700;

      background: var(--color-primary);
      color: var(--color-primary-text);

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__logout {
    display: flex;
    align-content: center;
    align-items: center;

    position: absolute;
    bottom: 0;

    padding: 10px 10px 10px 24px;

    text-decoration: none;

    color: $color-secondary-400;

    &:hover,
    &:focus,
    &:active {
      color: var(--color-primary-ui, $color-primary);
    }

    > svg {
      margin-right: 16px;

      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: $max-desktop) {
    @include fit(fixed);
    contain: layout;

    z-index: 10;

    pointer-events: none;

    &__inner {
      display: flex;
      justify-content: flex-end;

      width: 100%;
      height: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: none;

      position: relative;
      z-index: 1;

      padding: 0 var(--gutter) var(--gutter);

      height: 100%;
      width: 100%;
      max-width: $min-mobile;
      overflow-x: hidden;
      overflow-y: auto;

      pointer-events: auto;

      background: var(--color-background);

      transform: translate3d(105%, 0, 0);

      .open & {
        transform: none;

        transition: transform var(--transition-duration) var(--default-ease);
      }
    }

    &__backdrop {
      @include fit;
      z-index: 0;

      width: 100%;

      opacity: 0;

      background: #000;

      .open & {
        pointer-events: auto;

        opacity: 0.3;

        transition: opacity var(--transition-duration) var(--default-ease);
      }
    }

    &__close {
      display: flex;
      align-items: center;

      margin-left: auto;
      margin-right: calc(var(--container-gutter) - var(--gutter));

      height: var(--header-height);
    }

    &__subList {
      display: none;

      margin: 6px 0 0 60px;
    }
    [aria-current] + &__subList {
      display: block;
    }

    &__subItem {
      margin-bottom: 4px;
    }

    &__subLink {
      display: block;

      color: $color-secondary-400;

      &[aria-current] {
        font-weight: 700;

        color: var(--color-primary-ui, $color-primary);
      }
    }
  }

  @media (min-width: $min-desktop) {
    position: absolute;
    left: $page-gutter-desktop;
    top: 120px;
    bottom: 0;

    width: 208px;

    &__close {
      display: none;
    }

    &__inner {
      position: sticky;
      top: $gutter;

      padding-bottom: 70px;

      min-height: 50vh;
    }

    &__subList {
      display: none;
    }
  }
}
