@import 'styles/config';

.userSelect {
  display: inline-flex;

  position: relative;

  &__current {
    display: inline-flex;
    align-items: center;
  }

  &__chevron {
    @include select-chevron;
    &.active {
      transform: rotate(-180deg);
    }
  }

  &__users {
    position: absolute;
    top: 100%;
  }
}
