@import 'styles/config';

.navSecondary {
  @media (min-width: $min-desktop) {
    margin-bottom: $gutter;

    border-bottom: 1px solid $color-secondary-100;

    &__list {
      display: flex;
    }

    &__link {
      display: block;

      position: relative;

      padding: 0 $gutter 16px;

      color: $color-secondary-400;

      &::after {
        content: '';

        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;

        height: 1px;

        opacity: 0;

        background: var(--color-primary-ui, $color-primary);

        transition: opacity 0.15s ease-in-out;
      }

      &[aria-current] {
        font-weight: 700;

        color: var(--color-primary-ui, $color-primary);

        &::after {
          opacity: 1;
        }
      }

      &:hover,
      &:focus {
        text-decoration: none;

        &::after {
          opacity: 1;
        }
      }
    }
  }
}
