@import 'styles/config';

.loading {
  @include fit(fixed);

  contain: strict;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: $zindex-header + 1;

  user-select: none;

  background: var(--color-background);

  transition: opacity 200ms ease-in;

  &.enter {
    opacity: 0;

    // never show this fading in unless loading event isnt instant
    transition-delay: 200ms;
  }

  // only for reduced-motion
  &.enterActive {
    opacity: 1;

    // timeout before showing a loading spinner
    transition-delay: 3000ms;
  }

  &.exit {
    opacity: 0;
  }
}
